var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "solutions-area section-width ptb-100" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_1_3")))]),
        _c("p", [
          _vm._v(
            " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
          )
        ])
      ]),
      _c("div", { staticClass: "row justify-content-center mt-5" }, [
        _c("div", { staticClass: "category-navigate" }, [
          _c("ul", [
            _c("li", { staticClass: "btn btn-primary btn-lg" }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.goAnchor(".block1")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("section_1.title")))]
              )
            ]),
            _c("li", { staticClass: "btn btn-primary btn-lg" }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.goAnchor(".block2")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("section_2.title")))]
              )
            ]),
            _c("li", { staticClass: "btn btn-primary btn-lg" }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.goAnchor(".block3")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("section_3.title")))]
              )
            ]),
            _c("li", { staticClass: "btn btn-primary btn-lg" }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.goAnchor(".block4")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("section_4.title")))]
              )
            ])
          ])
        ])
      ]),
      _c("section", { staticClass: "blog-area pb-70" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "facility-section block1" }, [
            _c("div", { attrs: { id: "food" } }, [
              _c("h1", [_vm._v(_vm._s(_vm.$t("section_1.title")))]),
              _c("hr", { attrs: { size: "1" } })
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _c("i", { staticClass: "fas fa-utensils" }),
                        _vm._v(_vm._s(_vm.$t("section_1.item_2.title")) + " ")
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_2.content"))
                        }
                      }),
                      _vm._m(1)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters flex-row-reverse" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _c("i", { staticClass: "fas fa-store" }),
                        _vm._v(_vm._s(_vm.$t("section_1.item_3.title")) + " ")
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_3.content"))
                        }
                      }),
                      _vm._m(3)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(4),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _c("i", { staticClass: "fas fa-store" }),
                        _vm._v(_vm._s(_vm.$t("section_1.item_4.title")) + " ")
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_4.content"))
                        }
                      }),
                      _vm._m(5)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters flex-row-reverse" }, [
                  _vm._m(6),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _c("i", { staticClass: "fas fa-mug-hot" }),
                        _vm._v(_vm._s(_vm.$t("section_1.item_5.title")) + " ")
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_5.content"))
                        }
                      }),
                      _vm._m(7)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(8),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _c("i", { staticClass: "fas fa-mug-hot" }),
                        _vm._v(_vm._s(_vm.$t("section_1.item_6.title")) + " ")
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_6.content"))
                        }
                      }),
                      _vm._m(9)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters flex-row-reverse" }, [
                  _vm._m(10),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _c("i", { staticClass: "fas fa-utensils" }),
                        _vm._v(_vm._s(_vm.$t("section_1.item_7.title")) + " ")
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_1.item_7.content"))
                        }
                      }),
                      _vm._m(11)
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "facility-section block2" }, [
            _c("div", { attrs: { id: "product" } }, [
              _c("h1", [_vm._v(_vm._s(_vm.$t("section_2.title")))]),
              _c("hr", { attrs: { size: "1" } })
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-4 col-sm-6" }, [
                _c("div", { staticClass: "card h-100" }, [
                  _c("img", {
                    staticClass: "card-img-top",
                    attrs: {
                      src: require("assets/project_images/facility/art1.jpg"),
                      alt: "Image"
                    }
                  }),
                  _c("div", { staticClass: "card-body" }, [
                    _c("h5", { staticClass: "card-title" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("section_2.item_1.title")) + " "
                      )
                    ]),
                    _c("p", {
                      staticClass: "card-text text-left",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_2.item_1.content_1"))
                      }
                    })
                  ]),
                  _c("div", { staticClass: "card-footer text-left" }, [
                    _c("small", {
                      staticClass: "text-muted",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_2.item_1.content_2"))
                      }
                    })
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-lg-4 col-sm-6" }, [
                _c("div", { staticClass: "card h-100" }, [
                  _c("img", {
                    staticClass: "card-img-top",
                    attrs: {
                      src: require("assets/project_images/facility/art2.gif"),
                      alt: "Image"
                    }
                  }),
                  _c("div", { staticClass: "card-body" }, [
                    _c("h5", { staticClass: "card-title" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("section_2.item_2.title")) + " "
                      )
                    ]),
                    _c("p", {
                      staticClass: "card-text text-left",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_2.item_2.content_1"))
                      }
                    })
                  ]),
                  _c("div", { staticClass: "card-footer text-left" }, [
                    _c("small", {
                      staticClass: "text-muted",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_2.item_2.content_2"))
                      }
                    })
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-lg-4 col-sm-6" }, [
                _c("div", { staticClass: "card h-100" }, [
                  _c("img", {
                    staticClass: "card-img-top",
                    attrs: {
                      src: require("assets/project_images/facility/artFuture.gif"),
                      alt: "Image"
                    }
                  }),
                  _c("div", { staticClass: "card-body" }, [
                    _c("h5", { staticClass: "card-title" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("section_2.item_3.title")) + " "
                      )
                    ]),
                    _c("p", {
                      staticClass: "card-text text-left",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_2.item_3.content_1"))
                      }
                    })
                  ]),
                  _c("div", { staticClass: "card-footer text-left" }, [
                    _c("small", {
                      staticClass: "text-muted",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("section_2.item_3.content_2"))
                      }
                    })
                  ])
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "facility-section block3" }, [
            _c("div", { attrs: { id: "public-art" } }, [
              _c("h1", [_vm._v(_vm._s(_vm.$t("section_3.title")))]),
              _c("hr", { attrs: { size: "1" } })
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(12),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_3.item_1.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_3.item_1.content"))
                        }
                      }),
                      _vm._m(13)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(14),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_3.item_2.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_3.item_2.content"))
                        }
                      }),
                      _vm._m(15)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(16),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_3.item_3.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_3.item_3.content"))
                        }
                      }),
                      _vm._m(17)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(18),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_3.item_4.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_3.item_4.content"))
                        }
                      }),
                      _vm._m(19)
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-3" }, [
                _c("div", { staticClass: "row no-gutters" }, [
                  _vm._m(20),
                  _c("div", { staticClass: "col-md-8" }, [
                    _c("div", { staticClass: "card-body text-left" }, [
                      _c("h5", { staticClass: "card-title" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_3.item_5.title")) + " "
                        )
                      ]),
                      _c("p", {
                        staticClass: "card-text",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("section_3.item_5.content"))
                        }
                      }),
                      _vm._m(21)
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "facility-section block4" }, [
            _c("div", { attrs: { id: "public-art" } }, [
              _c("h1", [_vm._v(_vm._s(_vm.$t("section_4.title")))]),
              _c("hr", { attrs: { size: "1" } })
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-4 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "single-blog-post borrow-border-div" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("assets/project_images/facility/InfoCenter.jpg"),
                        alt: "Image"
                      }
                    }),
                    _c("div", { staticClass: "blog-content" }, [
                      _c("h3", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_4.item_1.title")) + " "
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_4.item_1.content")) + " "
                        )
                      ])
                    ])
                  ]
                )
              ]),
              _c("div", { staticClass: "col-lg-4 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "single-blog-post borrow-border-div" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("assets/project_images/facility/VIP.gif"),
                        alt: "Image"
                      }
                    }),
                    _c("div", { staticClass: "blog-content" }, [
                      _c("h3", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_4.item_2.title")) + " "
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_4.item_2.content")) + " "
                        )
                      ])
                    ])
                  ]
                )
              ]),
              _c("div", { staticClass: "col-lg-4 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "single-blog-post borrow-border-div" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("assets/project_images/facility/PressCenter.jpg"),
                        alt: "Image"
                      }
                    }),
                    _c("div", { staticClass: "blog-content" }, [
                      _c("h3", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_4.item_3.title")) + " "
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_4.item_3.content")) + " "
                        )
                      ])
                    ])
                  ]
                )
              ]),
              _c("div", { staticClass: "col-lg-4 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "single-blog-post borrow-border-div" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("assets/project_images/facility/Breastfeeding.jpg"),
                        alt: "Image"
                      }
                    }),
                    _c("div", { staticClass: "blog-content" }, [
                      _c("h3", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_4.item_4.title")) + " "
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_4.item_4.content")) + " "
                        )
                      ])
                    ])
                  ]
                )
              ]),
              _c("div", { staticClass: "col-lg-4 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "single-blog-post borrow-border-div" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("assets/project_images/facility/OrganizersOffice.gif"),
                        alt: "Image"
                      }
                    }),
                    _c("div", { staticClass: "blog-content" }, [
                      _c("h3", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_4.item_5.title")) + " "
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_4.item_5.content")) + " "
                        )
                      ])
                    ])
                  ]
                )
              ]),
              _c("div", { staticClass: "col-lg-4 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "single-blog-post borrow-border-div" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("assets/project_images/facility/FirstAid.gif"),
                        alt: "Image"
                      }
                    }),
                    _c("div", { staticClass: "blog-content" }, [
                      _c("h3", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_4.item_6.title")) + " "
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("section_4.item_6.content")) + " "
                        )
                      ])
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/facility/beef.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/facility/hi-life.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/facility/erabronze.gif"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/facility/tea.gif"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/facility/movenpick.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/facility/messe.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/facility/interplan.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/facility/Forwarders.gif"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/facility/eurotran.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/facility/cqebc.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-4" }, [
      _c("img", {
        attrs: {
          src: require("assets/project_images/facility/pioneer.jpg"),
          alt: "Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "card-text" }, [
      _c("small", { staticClass: "text-muted" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }